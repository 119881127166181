import { api } from "../config/api";
import { ResponseAPI, ResponseAuthenticate } from "../models/api";
import { UserAccess } from "../models/UserAccess";
import { InputLogin } from "../pages/login/Type";

export const doLogin = async (login: InputLogin): Promise<ResponseAPI<UserAccess | null>> => {
  try {
    let { data }: ResponseAPI<UserAccess> = await api.post(`/login/`, login);

    localStorage.setItem("accessToken", data.accessToken);
    localStorage.setItem("modules", JSON.stringify(data.modules));

    return {
      data,
      success: true
    } as ResponseAPI<UserAccess>
  }
  catch (error: any) {
    return {
      success: false,
    } as ResponseAPI<null>
  }
};


export const doAuthenticate = async (moduleId:number): Promise<ResponseAuthenticate> => {
  try {
    const { data }: { data: ResponseAPI<UserAccess> } = await api.post(`/login/Authenticate/`, {moduleId:moduleId});

    return {
      success: true
    } as ResponseAuthenticate
  }
  catch (error: any) {
    let msgError = "Sua sessão expirou, faça login novamente";

    if (typeof error.response?.status !== "undefined" && error.response?.status !== 401) {
      return {
        success: true
      } as ResponseAuthenticate
    }

    if (error.response?.data === "Acesso Simultaneo") {
      msgError = "Identificamos que sua conta está sendo acessada de outro local, você está sendo deslogado."
    }

    if (error.response?.data === "No Modules") {
      msgError = "Você não tem acesso a nenhum módulo da ferramenta.";
    }

    if (error.response?.data === "Unauthorized Module") {
      msgError = "Você não tem acesso a esse módulo da ferramenta";
    }

    return {
      success: false,
      message: msgError
    } as ResponseAuthenticate
  }
};
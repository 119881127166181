import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { format } from 'date-fns';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Container, AddBtn } from "./Style";
import { GetAllUsers, ToggleStrategy } from "../../services/UserService";
import { ResponseAPI } from '../../models/api';
import { User, UserListVM } from '../../models/UserAccess';
import { JsxElement } from 'typescript';
import { Switch } from '@mui/material';

interface Column {
  id: 'name' | 'email' | 'createDate' | 'planilha' | 'number';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: 'name', label: 'Nome', minWidth: 170 },
  { id: 'email', label: 'Email', minWidth: 100 },
  { id: 'createDate', label: 'Data de Criação', minWidth: 100 },
  { id: 'planilha', label: 'Planilhas', minWidth: 10 },
  { id: 'number', label: 'Numeros', minWidth: 10 }
];

export default function UserList() {
  const [userList, setUserList] = useState<UserListVM[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    GetUsers();
  }, []);

  const GetUsers = useCallback(async () => {
    const res: ResponseAPI<User[] | null> = await GetAllUsers();

    if (res.success && res.data) {
      const usersVM = res.data?.map(u => {
        return {
          createDate: u.createDate,
          email: u.email,
          name: u.name,
          planilha: u.userModules.find(um => um.moduleId == 1) != null,
          number: u.userModules.find(um => um.moduleId == 2) != null,
          userID: u.userID,
          password: u.password
        } as UserListVM;
      });
      setUserList(usersVM);

    };
  }, []);

  const changeToggle = async (columnName: string, userId: string, value: boolean): Promise<boolean> => {
    console.log(`Toggle btn: ${columnName} ${userId} ${value}`);
    console.log(value);

    const res: ResponseAPI<User[] | null> = await ToggleStrategy({
      enable: !value,
      moduleId: columnName == 'number' ? 2 : 1,
      userID: userId
    });

    GetUsers();

    if(res.success) return !value;

    return value;
  }

  return (
    <Container>
      <div>
        <ArrowBackIcon
          fontSize="large"
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/");
          }}
        />
        <span style={{ marginTop: 7, marginLeft: 20, position: "absolute" }}>
          <b>LISTA DE USUÁRIOS</b>
        </span>
      </div>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {userList
                .map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.userID}>
                      {columns.map((column) => {
                        const rowValue = row[column.id];
                        let value: string | boolean | JSX.Element = rowValue;
                        if (column.id === "createDate") {
                          value = format(new Date(`${rowValue}`), "dd/MM/yyyy HH:mm");
                        }

                        if (column.id === "number" || column.id === "planilha" ) {
                          value = (<Switch
                          checked={rowValue as boolean}
                          onChange={()=>changeToggle(column.id, row.userID,rowValue as boolean)}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />) as JSX.Element;
                        }

                        return (
                          <TableCell key={column.id} align={column.align}>
                            {value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <AddBtn onClick={() => {
        navigate("/users/create");
      }}>
        <AddIcon fontSize="large" />
      </AddBtn>
    </Container>
  );
}
import jwt_decode from "jwt-decode";
import { AppBar, Dialog, Grid, Hidden, Icon, MenuItem, Toolbar, Typography } from "@mui/material";
import { useLayoutEffect, useState } from "react";
import { Button as Btn } from "../../style/global";
import Layout from "../../components/Layout/Layout";
import { useNavigate } from "react-router-dom";
import PivotTableChartIcon from '@mui/icons-material/PivotTableChart';
import Filter9PlusIcon from '@mui/icons-material/Filter9Plus';
import { ResponseAuthenticate } from "../../models/api";
import { doAuthenticate } from "../../services/LoginService";
import { JwtDecode } from "../../models/UserAccess";

function Modules() {
    const navigate = useNavigate();
    const [isRole, setIsRole] = useState<string>("");
    const [validAccess, setValidAccess] = useState<boolean>(false);
    const userModules = JSON.parse(localStorage.getItem('modules') ?? "[]") as number[];

    useLayoutEffect(() => {
        const isValidAccess = async () => {
            const res: ResponseAuthenticate = await doAuthenticate(0);

            if (!res.success) {
                setValidAccess(false);
                return;
            }

            setValidAccess(true);
        };

        const accessToken = localStorage.getItem("accessToken");
        if (!!accessToken === false) {
            navigate('/login');
        }

        if (accessToken) {
            var decoded: JwtDecode = jwt_decode(accessToken);
            setIsRole(decoded.role);
        }

        isValidAccess();
    }, []);

    return (
        <Layout>
            {validAccess && (
                <>
                    <AppBar
                        position="static"
                        style={{
                            background:
                                "linear-gradient(41deg, rgba(0,212,255,1) 0%, rgba(0,0,0,1) 64%)",
                        }}
                    >
                        <Toolbar>
                            <Typography
                                variant="h6"
                                noWrap
                                component="div"
                                sx={{ display: { xs: "none", sm: "block" } }}
                            >
                                FERRAMENTA 27 TOP
                            </Typography>
                            <MenuItem>
                                <Grid container>
                                    <Grid md={1} xs={1} marginRight={"20px"}>

                                        <Hidden smUp={true}>
                                            <span style={{ marginLeft: 20 }}>FERRAMENTA 27 TOP</span>
                                        </Hidden>
                                    </Grid>

                                </Grid>
                            </MenuItem>

                        </Toolbar>
                    </AppBar>

                    <Grid container>
                        <Grid item xs={12} padding={"0px 20px 0px 20px"}>
                            <h2>Escolha o módulo que deseja operar</h2>
                        </Grid>
                        {
                            userModules.indexOf(1) != -1 && (
                                <Grid item xs={6} md={6} padding={"10px 20px 0px 20px"}>
                                    <Btn
                                        style={{ marginTop: 0 }}
                                        onClick={() => {
                                            navigate("/");
                                        }}
                                    >
                                        <PivotTableChartIcon></PivotTableChartIcon>
                                        Dúzias e Colunas
                                    </Btn>
                                </Grid>
                            )
                        }

                        {
                            userModules.indexOf(2) != -1 && (
                                <Grid item xs={6} md={6} padding={"10px 20px 10px 20px"}>
                                    <Btn
                                        style={{ marginTop: 0 }}
                                        onClick={() => {
                                            navigate("/numberstrategy");
                                        }}
                                    >
                                        <Filter9PlusIcon></Filter9PlusIcon>
                                        Números
                                    </Btn>
                                </Grid>
                            )
                        }

                        {(isRole === "MASTER" || isRole === "ADMIN") && (
                            <>
                                <Grid item xs={12} padding={"50px 20px 0px 20px"}>
                                    <h2 style={{margin:0}}>Área para Admins</h2>

                                </Grid>
                                <Grid item xs={4} padding={"0px 20px 0px 20px"}>

                                    <Btn

                                        onClick={() => {
                                            navigate("/users");
                                        }}
                                    >
                                        Usuários
                                    </Btn>
                                </Grid>

                            </>

                        )}


                    </Grid>
                </>
            )}
        </Layout>
    );
}

export default Modules;


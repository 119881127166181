import styled from "styled-components";

export const Container = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Box = styled.div`
    max-width: 400px;
    background: #8888;
    margin: 20px;
    border: 1px solid #999;
    border-radius: 10px;
    padding: 20px;
    padding-right: 23px;
    padding-top: 0px;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 40px;
`;
// import React, { useMemo } from 'react';
import { ExternalTypes, Colors, NumberTypes } from "../models";
import { Confirmation } from "../models/Confirmation";
import { Columns } from "../models/enums/Columns";
import { Dozens } from "../models/enums/Dozens";
import { Pattern } from "../models/enums/Pattern";

interface TransformedArrays {
  colors: Colors[];
  oddEven: ExternalTypes[];
  numberTypes: NumberTypes[];
  dozens: Dozens[];
  columns: Columns[];
  terminals: number[];
}

export interface TransformedNumber {
  color: Colors;
  oddEven: ExternalTypes;
  numberType: NumberTypes;
  dozen: Dozens;
  column: Columns;
  number:number;
}

const BlackNumbers = [
  2, 4, 6, 8, 10, 11, 13, 15, 17, 20, 22, 24, 26, 28, 29, 31, 33, 35,
];
const RedNumbers = [
  1, 3, 5, 7, 9, 12, 14, 16, 18, 19, 21, 23, 25, 27, 30, 32, 34, 36,
];
const columns = {
  C1: [1, 4, 7, 10, 13, 16, 19, 22, 25, 28, 31, 34],
  C2: [2, 5, 8, 11, 14, 17, 20, 23, 26, 29, 32, 35],
  C3: [3, 6, 9, 12, 15, 18, 21, 24, 27, 30, 33, 36],
};
const dozens = {
  D1: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  D2: [13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24],
  D3: [25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36],
};


const useRouletteNumbersAnalysis = (numbersArray: number[]) => {

  const getNeighboors = (number:number, howManyNeighboors: number): number[] => {
    const raceTrack = [0,32,15,19,4,21,2,25,17,34,6,27,13,36,11,30,8,23,10,5,24,16,33,1,20,14,31,9,22,18,29,7,28,12,35,3,26];
  
    const startPoint = raceTrack.indexOf(number);
    let counter = 1;
    const neighboors: number[] = [];
    do
    {
        let rightIndex = startPoint + counter;
        let leftIndex = startPoint - counter;
  
        if(rightIndex > raceTrack.length-1) rightIndex = rightIndex - raceTrack.length;
        if(leftIndex < 0) leftIndex = raceTrack.length + leftIndex;
  
        neighboors.push(raceTrack[leftIndex]);
        neighboors.push(raceTrack[rightIndex]);
  
        counter++;
    }while(counter <= howManyNeighboors)
    
    return neighboors;
  };

  const transformNumber = (number: number): TransformedNumber => {
    return {
      number: number,
      color:
        BlackNumbers.indexOf(number) !== -1
          ? Colors.PRETOS
          : RedNumbers.indexOf(number) !== -1
          ? Colors.VERMELHOS
          : Colors.VERDE,
      oddEven: number % 2 === 0 ? ExternalTypes.PAR : ExternalTypes.IMPAR,
      numberType:
        number === 0
          ? NumberTypes.ZERO
          : number < 19
          ? NumberTypes.BAIXOS
          : NumberTypes.ALTOS,
      column:
        columns.C1.indexOf(number) !== -1
          ? Columns.C1
          : columns.C2.indexOf(number) !== -1
          ? Columns.C2
          : Columns.C3,
      dozen:
        dozens.D1.indexOf(number) !== -1
          ? Dozens.D1
          : dozens.D2.indexOf(number) !== -1
          ? Dozens.D2
          : Dozens.D3,
    };
  };

  const TransformArray = (): TransformedArrays => {
    const result: TransformedArrays = {
      colors: [],
      numberTypes: [],
      oddEven: [],
      dozens: [],
      columns: [],
      terminals: []
    };

    numbersArray.forEach((n) => {
      const transformedNumbers = transformNumber(n);
      result.colors.push(transformedNumbers.color);
      result.numberTypes.push(transformedNumbers.numberType);
      result.oddEven.push(transformedNumbers.oddEven);
      result.columns.push(transformedNumbers.column);
      result.dozens.push(transformedNumbers.dozen);
      result.terminals.push(Number(`${n}`.slice(-1)));
    });

    return result;
  };

  const numbers = TransformArray();

  const PullNumbersLogic = (): Confirmation[] => {
    const lastNumber = numbersArray[numbersArray.length - 1];

    if((lastNumber ?? 0) <= 0 || lastNumber > 36) return [];

    const pullNumbers = [
      [10,21,34,36], // after 1
      [20,22,33,35], // after 2
      [23,30,33,36], // after 3
      [2,6,8,24], // after 4
      [3,10,25,30], // after 5
      [4,8,9,27 ], // after 6
      [6,14,30,36], // after 7
      [2,6,8,19], // after 8
      [6,12,19,33], // after 9
      [2,5,11,13], // after 10
      [10,12,15,36], // after 11
      [9,14,24,28], // after 12
      [10,15,21,36], // after 13
      [7,12,16,18], // after 14
      [13,14,17,30], // after 15
      [8,17,18,33], // after 16
      [13,15,31,35], // after 17
      [9,14,19,36], // after 18
      [9,13,21,33], // after 19
      [2,17,19,22], // after 20
      [1,19,31,36], // after 21
      [2,9,20,36], // after 22
      [3,10,21,36 ], // after 23
      [4,12,16,28], // after 24
      [3,5,27,30], // after 25
      [5,6,13,14], // after 26
      [7,10,13,30], // after 27
      [12,13,24,31], // after 28
      [9,24,30,32], // after 29
      [3,7,25,28], // after 30
      [1,8,21,28], // after 31
      [10,12,16,18], // after 32
      [2,3,16,29], // after 33
      [14,17,24,31], // after 34
      [2,8,11,17], // after 35
      [2,8,11,17] // after 36
    ];

    const result: Confirmation[] = [];
    let matches:number[] = pullNumbers[lastNumber - 1];
    const iterationLimit = matches.length;
    const text = `Entrada nos números ${matches.join(" ")}`;

    for(let i = 0; i < iterationLimit; i++){
      matches.push(...getNeighboors(matches[i],1));
    }

    result.push({
      betText: text,
      pattern: getNPattern(lastNumber),
      match: matches as number[]
    });
    
    return result;
  }

  const getNPattern = (n:number): Pattern => {
    switch(n){
      case 1 : return Pattern.N1;
      case 2 : return Pattern.N2;
      case 3 : return Pattern.N3;
      case 4 : return Pattern.N4;
      case 5 : return Pattern.N5;
      case 6 : return Pattern.N6;
      case 7 : return Pattern.N7;
      case 8 : return Pattern.N8;
      case 9 : return Pattern.N9;
      case 10 : return Pattern.N10;
      case 11 : return Pattern.N11;
      case 12: return Pattern.N12;
      case 13: return Pattern.N13;
      case 14: return Pattern.N14;
      case 15: return Pattern.N15;
      case 16: return Pattern.N16;
      case 17: return Pattern.N17;
      case 18: return Pattern.N18;
      case 19: return Pattern.N19;
      case 20: return Pattern.N20;
      case 21: return Pattern.N21;
      case 22: return Pattern.N22;
      case 23: return Pattern.N23;
      case 24: return Pattern.N24;
      case 25: return Pattern.N25;
      case 26: return Pattern.N26;
      case 27: return Pattern.N27;
      case 28: return Pattern.N28;
      case 29: return Pattern.N29;
      case 30: return Pattern.N30;
      case 31: return Pattern.N31;
      case 32: return Pattern.N32;
      case 33: return Pattern.N33;
      case 34: return Pattern.N34;
      case 35: return Pattern.N35;
      case 36: return Pattern.N36;
      default: return Pattern.N1;
    }
  }

  const RouletteAnalisys = (): Confirmation[] => {
    const result: Confirmation[] = [
      ...PullNumbersLogic()
    ];
    return result;
  };

  return {
    RouletteAnalisys,
    transformNumber,
  };
};

export default useRouletteNumbersAnalysis;

import axios from "axios";

export const api = axios.create({
    headers: {
        "Content-Type": "application/json"
    },
    // baseURL: "https://localhost:44315/api"
    baseURL: "https://intelprog-001-site2.btempurl.com/api"
});

api.interceptors.request.use(
    async (config) => {
        const accessToken = localStorage.getItem("accessToken");
        if (!!accessToken) {
            config.headers.Authorization = `Bearer ${accessToken}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);
import styled from "styled-components";

export const Container = styled.div`
  margin: 30px;
`;

export const ContainerList = styled.div`
  max-width: 800px;
  margin: 0 auto;
  margin-top: 20px;
  border: 1px solid #333;
  border-radius: 10px;
  padding: 15px;
`;


export const ContentPassword = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
`;

export const AddBtn = styled.div`
  height: 60px;
  width: 60px;
  border-radius: 100px;
  background: green;
  position: fixed;
  right: 30px;
  bottom: 30px;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  transition: background 1s;
  :hover {
    background: yellowgreen;
    transition: background 1s;
  }
`;
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/Layout/Layout";
import { doLogin } from "../../services/LoginService";
import { Box, Container, Content } from "./Style";
import { Center, MsgError, Button, Input } from "./../../style/global";
import { InputLogin } from "./Type";
import { ResponseAPI } from "../../models/api";
import { UserAccess } from "../../models/UserAccess";
import loadingImg from "../../assets/images/loading.gif";
import logo from "../../assets/images/logo.jpeg";

function Login() {
  const [input, setInput] = useState<InputLogin>({} as InputLogin);
  const [loading, setLoading] = useState<boolean>(false);
  const [msgLogin, setMsgLogin] = useState<string>();
  const navigate = useNavigate();

  const handleInput = (inputName: string, inputValue: string) => {
    setInput({
      ...input,
      [inputName]: inputValue
    });
  }

  const submitLogin = async () => {
    setMsgLogin("");

    if (!!!input.email || !!!input.password) {
      setMsgLogin("*Preencha todos os campos");
      return;
    }

    setLoading(true);
    const res: ResponseAPI<UserAccess | null> = await doLogin(input);

    if (res.success) {
      if((res.data?.modules?.length ?? 0) == 0 ){
        navigate('/');
      }else if((res.data?.modules?.length ?? 0) > 1){
        navigate('/modules');
      }else if(res.data?.modules[0] == 1){
        navigate('/');
      }else if(res.data?.modules[0] == 2){
        navigate('/numberstrategy');
      }
    } else {
      setMsgLogin("*Usuário ou senha incorretos");
    }

    setLoading(false);
  }

  return (
    <Layout>
      <Container>
        <Content>
          <img src={logo} width="100%" />
          <Box>
            <Input
              type="tex"
              placeholder="Insira aqui seu usuário"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInput("email", e.target.value)}
            >

            </Input>
            <Input
              type="password"
              placeholder="Insira aqui sua senha"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInput("password", e.target.value)}
            >
            </Input>
            <Center>
              {!loading && (
                <Button
                  onClick={() => submitLogin()}
                >
                  Entrar
                </Button>
              )}
              {loading && (
                <img src={loadingImg} height="30" style={{ marginTop: 30 }} />
              )}
            </Center>
            <MsgError>
              {msgLogin}
            </MsgError>
          </Box>
        </Content>
      </Container>
    </Layout>
  );
}

export default Login;

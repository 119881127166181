/* eslint-disable react/prop-types */
import React from "react";
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#F00",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#0F0",
  },
}));

type LinearProgressBarProps = {
    value: number;
}

const LinearProgressBar = (props: LinearProgressBarProps): JSX.Element => {
    return (
        <BorderLinearProgress variant="determinate" value={props.value} />
    )
}

export default LinearProgressBar;
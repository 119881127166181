export enum Pattern {
    AlienP2 = "Planilha 2",
    AlienP1 = "Planilha 1",
    AlienP3 = "Planilha 3",
    AlienDozen = "Planilha Duzia 1",
    AlienDozen_Broken = "Planilha Duzia 2",

    //estrategia baseada em numeros
    N1 = "Num 1",
    N2 = "Num 2",
    N3 = "Num 3",
    N4 = "Num 4",
    N5 = "Num 5",
    N6 = "Num 6",
    N7 = "Num 7",
    N8 = "Num 8",
    N9 = "Num 9",
    N10 = "Num 10",
    N11 = "Num 11",
    N12 = "Num 12",
    N13 = "Num 13",
    N14 = "Num 14",
    N15 = "Num 15",
    N16 = "Num 16",
    N17 = "Num 17",
    N18 = "Num 18",
    N19 = "Num 19",
    N20 = "Num 20",
    N21 = "Num 21",
    N22 = "Num 22",
    N23 = "Num 23",
    N24 = "Num 24",
    N25 = "Num 25",
    N26 = "Num 26",
    N27 = "Num 27",
    N28 = "Num 28",
    N29 = "Num 29",
    N30 = "Num 30",
    N31 = "Num 31",
    N32 = "Num 32",
    N33 = "Num 33",
    N34 = "Num 34",
    N35 = "Num 35",
    N36 = "Num 36"
}

/* eslint-disable react/prop-types */
import React from "react";

type LayoutProps = {
    children: React.ReactNode;
}

const Layout = (props: LayoutProps): JSX.Element => {
    return (
        <>
            {props.children}
        </>
    )
}

export default Layout;
import styled from "styled-components";

export const Center = styled.div`
    text-align: center;
`;

export const MsgError = styled.div`
    font-size: 13px;
    color: #ff5656;
    text-align: center;
    margin-top: 20px;
`;

export const Button = styled.button`
    height: 40px;
    width: 160px;
    color: #fff;
    background: #333;
    border: 1px solid #666;
    border-radius: 5px;
    margin-top: 30px;
    transition: background 1s;
    cursor: pointer;
    :hover {
        transition: background 1s;
        background: #666;
    }
`;

export const Input = styled.input`
    height: 40px;
    font-size: 15px;
    width: 100%;
    background: #fff;
    border: 0;
    border-radius: 5px;
    margin-top: 25px;
    text-indent: 10px;
    box-shadow: 0px 12px 23px #333;
`;
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { CreateNewUser } from "../../services/UserService";
import { generatePassword } from "./../../util/password";
import { ContainerList, ContentPassword } from "./Style";
import { Center, Button, Input } from "./../../style/global";
import loadingImg from "../../assets/images/loading.gif";
import { User } from "../../models/UserAccess";
import { ResponseAPI } from "../../models/api";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { validateEmail } from "../../util/email";

export default function UserCreate() {
  const [input, setInput] = useState<User>({
    password: ''
  } as User);
  const [loading, setLoading] = useState<boolean>(false);
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [msgAlert, setMsgAlert] = useState<{ title: string, msg: string, success: boolean }>();
  const navigate = useNavigate();

  const handleInput = (inputName: string, inputValue: string) => {
    setInput({
      ...input,
      [inputName]: inputValue
    });
  }

  const submitLogin = async () => {

    if (!!!input.name || !!!input.email || !!!input.password) {
      setOpenAlert(true);
      setMsgAlert({
        title: "ERRO",
        msg: "*Preencha todos os campos",
        success: false
      });
      return;
    }

    if (validateEmail(input.email) === null) {
      setOpenAlert(true);
      setMsgAlert({
        title: "ERRO",
        msg: "*Email é inválido",
        success: false
      });
      return;
    }

    setLoading(true);

    const res: ResponseAPI<User | null> = await CreateNewUser(input);

    if (res.success) {
      setMsgAlert({
        title: "USUÁRIO CRIADO",
        msg: "O usuário foi criado com sucesso!",
        success: true
      });
      setOpenAlert(true);
    } else {
      setMsgAlert({
        title: "ERRO",
        msg: "Erro ao criar o usuário",
        success: false
      });
      setOpenAlert(true);
    }

    setLoading(false);
  }

  const genertaPassword = () => {
    var password = generatePassword(9);
    handleInput("password", password);
  };

  const handleClose = () => {
    setOpenAlert(false);
    if (msgAlert?.success) {
      navigate("/users");
    }
  };

  return (
    <ContainerList>
      <Dialog
        open={openAlert}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {msgAlert?.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {msgAlert?.msg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()} autoFocus>OK</Button>
        </DialogActions>
      </Dialog>
      <div>
        <ArrowBackIcon
          fontSize="large"
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/users");
          }}
        />
        <span style={{ marginTop: 7, marginLeft: 20, position: "absolute" }}>
          <b>CRIAR USUÁRIO</b>
        </span>
      </div>
      <Input
        type="tex"
        placeholder="Nome"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInput("name", e.target.value)}
      />
      <Input
        type="tex"
        placeholder="Email"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInput("email", e.target.value)}
      />
      <ContentPassword>
        <Input
          type="text"
          placeholder="Senha"
          value={input.password}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInput("password", e.target.value)}
        />
        <Button
          onClick={() => genertaPassword()}
          style={{
            marginTop: 25
          }}
        >
          <b>GERAR</b>
        </Button>
      </ContentPassword>
      <Center>
        {!loading && (
          <Button
            onClick={() => submitLogin()}
          >
            <b>CRIAR</b>
          </Button>
        )}
        {loading && (
          <img src={loadingImg} height="30" style={{ marginTop: 30 }} />
        )}
      </Center>
    </ContainerList>
  )
};
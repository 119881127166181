import jwt_decode from "jwt-decode";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  AppBar,
  Button,
  FormControlLabel,
  Grid,
  Hidden,
  MenuItem,
  Switch,
  Toolbar,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';
import { useNavigate } from "react-router-dom";
import React, { useLayoutEffect, useMemo, useState } from "react";
import "./App.css";
import Layout from "./components/Layout/Layout";
import useRouletteAnalysis, {
  TransformedNumber,
} from "./hooks/useRouletteAnalysis";
import { Colors, ExternalTypes, NumberTypes } from "./models";
import { Confirmation } from "./models/Confirmation";
import { Columns } from "./models/enums/Columns";
import { Dozens } from "./models/enums/Dozens";
import { Pattern } from "./models/enums/Pattern";
import Indicator from "./pages/home/components/Indicator/Indicator";
import NumberHistory from "./pages/home/components/NumbersHistory";
import { doAuthenticate } from "./services/LoginService";
import { ResponseAuthenticate } from "./models/api";
import { Button as Btn } from "./style/global";
import { JwtDecode } from "./models/UserAccess";

function App() {
  const navigate = useNavigate();
  const [numbers, setNumbers] = useState([] as number[]);
  const [gale1Confirmations, setGale1Confirmations] = useState(
    [] as Confirmation[]
  );
  const [inputValue, setInputValue] = useState("");
  const [greenCount, setGreenCount] = useState(0);
  const [redCount, setRedCount] = useState(0);
  const [assertivity, setAssertivity] = useState(77 as number);
  const [showGale, setShowGale] = React.useState(true);
  const [validAccess, setValidAccess] = useState<boolean>(false);
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [msgAlert, setMsgAlert] = useState<string>();
  const [isRole, setIsRole] = useState<string>("");

  useLayoutEffect(() => {
    const isValidAccess = async () => {
      const res: ResponseAuthenticate = await doAuthenticate(1);

      if (!res.success) {
        setValidAccess(false);
        setMsgAlert(res.message);
        setOpenAlert(true);
        return;
      }

      setOpenAlert(false);
      setValidAccess(true);
    };

    const accessToken = localStorage.getItem("accessToken");
    if (!!accessToken === false) {
      navigate('/login');
    } 

    if (accessToken) {
      var decoded: JwtDecode = jwt_decode(accessToken);
      setIsRole(decoded.role);
    }

    isValidAccess();
  }, [numbers]);


  const handleClose = () => {
    setOpenAlert(false);
    navigate('/login');
  };

  const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });


  const { RouletteAnalisys, transformNumber } = useRouletteAnalysis(
    numbers.length > 5 ? numbers.slice(numbers.length - 5) : numbers
  );

  const [statistics, setStatistics] = useState({
    dozens: {
      d1: 0,
      d2: 0,
      d3: 0,
    },
    columns: {
      c1: 0,
      c2: 0,
      c3: 0,
    },
    colors: {
      black: 0,
      red: 0,
    },
    externalType: {
      even: 0,
      odd: 0,
    },
    numberType: {
      high: 0,
      low: 0,
    },
  });

  const [suggestionResults, setSuggestionResults] = useState([
    {
      pattern: Pattern.AlienP1,
      greens: 0,
      gale1: 0,
      reds: 0,
      show: true,
    },
    {
      pattern: Pattern.AlienP2,
      greens: 0,
      gale1: 0,
      reds: 0,
      show: true,
    },
    {
      pattern: Pattern.AlienP3,
      greens: 0,
      gale1: 0,
      reds: 0,
      show: true,
    },
    {
      pattern: Pattern.AlienDozen,
      greens: 0,
      gale1: 0,
      reds: 0,
      show: true,
    },
    {
      pattern: Pattern.AlienDozen_Broken,
      greens: 0,
      gale1: 0,
      reds: 0,
      show: true,
    },
  ]);

  const resetStates = () => {
    setSuggestionResults([
      {
        pattern: Pattern.AlienP1,
        greens: 0,
        gale1: 0,
        reds: 0,
        show: true,
      },
      {
        pattern: Pattern.AlienP2,
        greens: 0,
        gale1: 0,
        reds: 0,
        show: true,
      },
      {
        pattern: Pattern.AlienP3,
        greens: 0,
        gale1: 0,
        reds: 0,
        show: true,
      },
      {
        pattern: Pattern.AlienDozen,
        greens: 0,
        gale1: 0,
        reds: 0,
        show: true,
      },
      {
        pattern: Pattern.AlienDozen_Broken,
        greens: 0,
        gale1: 0,
        reds: 0,
        show: true,
      },
    ]);

    setStatistics({
      dozens: {
        d1: 0,
        d2: 0,
        d3: 0,
      },
      columns: {
        c1: 0,
        c2: 0,
        c3: 0,
      },
      colors: {
        black: 0,
        red: 0,
      },
      externalType: {
        even: 0,
        odd: 0,
      },
      numberType: {
        high: 0,
        low: 0,
      },
    });

    setNumbers([] as number[]);

    setShowGale(true);

    setGale1Confirmations([]);

    setGreenCount(0);
    setRedCount(0);

    setAssertivity(77);
  }

  const normalizeValue = (value: number, maxValue: number) =>
    (value * 100) / maxValue || 0;

  const showBetSuggestion = (patternSuggestion: Confirmation): boolean => {
    const suggestionResult = suggestionResults.filter(
      (sr) => sr.pattern === patternSuggestion.pattern
    )[0];
    return (
      suggestionResult.greens > 0 &&
      suggestionResult.show &&
      normalizeValue(
        suggestionResult.greens + suggestionResult.gale1,
        suggestionResult.greens + suggestionResult.gale1 + suggestionResult.reds
      ) > assertivity
    );
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const translateStatistics = (number: number) => {
    const translatedNumber: TransformedNumber = transformNumber(number);
    let statisticsToUpdate = statistics;

    if (translatedNumber.dozen === Dozens.D1) statisticsToUpdate.dozens.d1++;
    if (translatedNumber.dozen === Dozens.D2) statisticsToUpdate.dozens.d2++;
    if (translatedNumber.dozen === Dozens.D3) statisticsToUpdate.dozens.d3++;

    if (translatedNumber.column === Columns.C1) statisticsToUpdate.columns.c1++;
    if (translatedNumber.column === Columns.C2) statisticsToUpdate.columns.c2++;
    if (translatedNumber.column === Columns.C3) statisticsToUpdate.columns.c3++;

    if (translatedNumber.color === Colors.PRETOS)
      statisticsToUpdate.colors.black++;
    if (translatedNumber.color === Colors.VERMELHOS)
      statisticsToUpdate.colors.red++;

    if (translatedNumber.numberType === NumberTypes.ALTOS)
      statisticsToUpdate.numberType.high++;
    if (translatedNumber.numberType === NumberTypes.BAIXOS)
      statisticsToUpdate.numberType.low++;

    if (translatedNumber.oddEven === ExternalTypes.PAR)
      statisticsToUpdate.externalType.even++;
    if (translatedNumber.oddEven === ExternalTypes.IMPAR)
      statisticsToUpdate.externalType.odd++;

    confirmBets(translatedNumber);

    setStatistics(statisticsToUpdate);
  };

  const addNumber = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const numberToAdd = Number(inputValue);
    if (numberToAdd < 0 || numberToAdd > 36) {
      setInputValue("");
      return;
    }

    translateStatistics(numberToAdd);

    setNumbers([...numbers, numberToAdd]);

    setInputValue("");
  };

  const setSuggestionResult = (
    color: Colors,
    pattern: Pattern,
    gale: number = 0
  ) => {
    let suggestionResultsUp = suggestionResults;
    const index = suggestionResultsUp.findIndex((sr) => sr.pattern === pattern);

    if (color === Colors.VERDE) {
      setGreenCount(greenCount + 1);
      switch (gale) {
        case 0:
          suggestionResultsUp[index].greens++;
          break;
        case 1:
          suggestionResultsUp[index].gale1++;
          break;
      }
    }
    if (color === Colors.VERMELHOS) {
      setRedCount(redCount + 1);
      suggestionResultsUp[index].reds++;
    }

    setSuggestionResults(suggestionResultsUp);
  };

  const analisysResult = useMemo(() => {
    return RouletteAnalisys();
  }, [numbers]);

  const confirmBets = (transformedNumber: TransformedNumber) => {
    gale1Confirmations.forEach((gale) => {
      if (gale.match) {
        if (
          [Pattern.AlienP2, Pattern.AlienP1, Pattern.AlienP3].some(
            (p) => p === gale.pattern
          ) &&
          gale.match.some((m: Columns) => m === transformedNumber.column)
        ) {
          // console.log("match gale column",gale);
          setSuggestionResult(Colors.VERDE, gale.pattern, 1);
          return;
        }

        if (
          [Pattern.AlienDozen, Pattern.AlienDozen_Broken].some(
            (p) => p === gale.pattern
          ) &&
          gale.match.some((m: Dozens) => m === transformedNumber.dozen)
        ) {
          // console.log("match gale dozen",gale);

          setSuggestionResult(Colors.VERDE, gale.pattern, 1);
          return;
        }
      }

      // console.log("doesn't match on Gale 1 - RED", gale);
      setSuggestionResult(Colors.VERMELHOS, gale.pattern, 1);
    });

    setGale1Confirmations([] as Confirmation[]);
    const garray = [] as Confirmation[];

    analisysResult.forEach((ar) => {
      if (ar.match) {
        if (
          [Pattern.AlienP2, Pattern.AlienP1, Pattern.AlienP3].some(
            (p) => p === ar.pattern
          ) &&
          ar.match.some((m: Columns) => m === transformedNumber.column)
        ) {
          // console.log("match column",ar);
          setSuggestionResult(Colors.VERDE, ar.pattern);
          return;
        }

        if (
          [Pattern.AlienDozen, Pattern.AlienDozen_Broken].some(
            (p) => p === ar.pattern
          ) &&
          ar.match.some((m: Dozens) => m === transformedNumber.dozen)
        ) {
          // console.log("match dozen",ar);

          setSuggestionResult(Colors.VERDE, ar.pattern);
          return;
        }
      }

      // console.log("doesn't match sending to gale", ar);
      if (showGale) garray.push(ar);
      else setSuggestionResult(Colors.VERMELHOS, ar.pattern);
    });
    setGale1Confirmations(garray);
    // console.log("Gale Array", gale1Confirmations);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (numbers.length && numbers.length > 0)
      alert("Não é permitido alterar depois de inserir números");
    else setShowGale(event.target.checked);
  };

  const handleStrategyToggleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    pattern: Pattern
  ) => {
    let suggestionResultsUpdated = [...suggestionResults];
    const index = suggestionResultsUpdated.findIndex(
      (sr) => sr.pattern === pattern
    );

    suggestionResultsUpdated[index].show = event.target.checked;

    setSuggestionResults(suggestionResultsUpdated);
  };

  return (
    <Layout>
      <Dialog
        open={openAlert}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Ops!"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {msgAlert}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()} autoFocus>OK</Button>
        </DialogActions>
      </Dialog>
      {validAccess && (
        <>
          <AppBar
            position="static"
            style={{
              background:
                "linear-gradient(41deg, rgba(0,212,255,1) 0%, rgba(0,0,0,1) 64%)",
            }}
          >
            <Toolbar>
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ display: { xs: "none", sm: "block" } }}
              >
                FERRAMENTA 27 TOP
              </Typography>
              <MenuItem>
                <Grid container>
                  <Grid md={1} xs={1} marginRight={"20px"}>
                    <input
                      type="number"
                      value={assertivity}
                      style={{ width: "100%" }}
                      onChange={(e) =>
                        setAssertivity(Number(e.target.value ?? "0"))
                      }
                    />
                    <Hidden smUp={true}>
                      <span style={{ marginLeft: 20 }}>PLANILHAS TOP 27</span>
                    </Hidden>
                  </Grid>
                  <Grid md={10}>
                    <span style={{ fontSize: "12px" }}>
                      * Mostrar sugestões a partir dessa porcentagem de
                      assertividade
                    </span>
                  </Grid>
                </Grid>
              </MenuItem>
              {(isRole === "MASTER" || isRole === "ADMIN") && (
                <Grid md={10}>
                  <Btn
                    style={{ marginTop: 0 }}
                    onClick={() => {
                      navigate("/users");
                    }}
                  >
                    Usuários
                  </Btn>
                </Grid>
              )}
            </Toolbar>
          </AppBar>

          <Grid container>
            <Grid item xs={12} padding={"10px 20px 0px 20px"}>
              <div className="App">
                <header className="App-header">
                  <div>
                    <span>Mostrar Gale</span>
                    <Switch
                      checked={showGale}
                      onChange={handleChange}
                      inputProps={{ "aria-label": "controlled" }}
                      color="secondary"
                    />
                  </div>

                  {showGale && (
                    <>
                      <span style={{ color: "green" }}>Gale 1</span>
                      <div
                        style={{
                          border: "1px solid green",
                          width: "100%",
                          marginBottom: 20,
                        }}
                      >
                        {gale1Confirmations.map((a) => {
                          return (
                            <div>
                              {showBetSuggestion(a) && (
                                <div>
                                  <h5 style={{ margin: 0 }}>
                                    <span>{a.pattern}: </span>
                                    {a.betText}
                                  </h5>
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </>
                  )}

                  {analisysResult.map((a) => {
                    return (
                      <div style={{ display: "inline-flex" }}>
                        {showBetSuggestion(a) && (
                          <div>
                            <h5 style={{ margin: 0 }}>
                              <span>{a.pattern}: </span>
                              {a.betText}
                            </h5>
                          </div>
                        )}
                      </div>
                    );
                  })}

                  <form
                    onSubmit={(e) => addNumber(e)}
                    style={{ marginTop: "50px" }}
                  >
                    <input
                      type="number"
                      value={inputValue}
                      onChange={(e) => handleOnChange(e)}
                      style={{
                        height: "30px",
                        width: "50px",
                        marginRight: "10px",
                        fontSize: "20px",
                        textAlign: "center",
                        fontWeight: "bold",
                      }}
                    />
                    <input
                      type={"submit"}
                      value="ADICIONAR"
                      style={{ height: "30px" }}
                    />

                    <Button onClick={resetStates} variant="outlined">LIMPAR</Button>
                    <br />
                    <span style={{ fontSize: "12px" }}>
                      * Adicione os números na sequência que sairam na roleta
                    </span>
                  </form>

                  <NumberHistory numbers={numbers} size={10} />
                </header>
              </div>

              <Grid container spacing={2} style={{ textAlign: "center" }}>
                <Grid xs={12} md={1} sm={1} item></Grid>
                {suggestionResults.map((sr) => {
                  return (
                    <Grid xs={6} md={2} sm={2} item>
                      <Grid container>
                        <Grid xs={6} md={6} sm={6}>
                          <span style={{ color: "white" }}>
                            {normalizeValue(
                              sr.greens + (showGale ? sr.gale1 : 0),
                              sr.greens + (showGale ? sr.gale1 : 0) + sr.reds
                            ).toFixed(2)}
                            %
                          </span>
                        </Grid>
                        <Grid xs={6} md={6} sm={6}>
                          <FormControlLabel
                            control={
                              <Switch
                                size={"small"}
                                checked={sr.show}
                                onChange={(ev) =>
                                  handleStrategyToggleChange(ev, sr.pattern)
                                }
                                inputProps={{ "aria-label": "controlled" }}
                                color="secondary"
                              />
                            }
                            label={
                              <span style={{ color: "white" }}>
                                {sr.show && (
                                  <Visibility fontSize="small"></Visibility>
                                )}
                                {!sr.show && (
                                  <VisibilityOff fontSize="small"></VisibilityOff>
                                )}
                              </span>
                            }
                            labelPlacement="start"
                          />
                        </Grid>
                      </Grid>

                      <Indicator
                        value={normalizeValue(
                          sr.greens + (showGale ? sr.gale1 : 0),
                          sr.greens + (showGale ? sr.gale1 : 0) + sr.reds
                        )}
                        title={sr.pattern}
                      />
                      <div style={{ color: "#FFF", fontSize: "12px" }}>
                        <Grid container>
                          <Grid xs={4}>
                            Green:{" "}
                            <span style={{ color: "green" }}>{sr.greens}</span>
                          </Grid>
                          {showGale && (
                            <Grid xs={4}>
                              Gale 1:{" "}
                              <span style={{ color: "green" }}>{sr.gale1}</span>
                            </Grid>
                          )}

                          <Grid xs={4}>
                            Red: <span style={{ color: "red" }}>{sr.reds}</span>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  );
                })}
              </Grid>

              {/* <Grid container style={{ color: "white" }}>
              <Grid xs={12} textAlign="center">
                <h5 style={{marginBottom:0}}>
                  Bateu meta? Lucrou? Considere me pagar um café ☕. <br/>
                  {"Pix > j.fabricio6@gmail.com"}
                </h5>
              </Grid>
            </Grid> */}

              <Grid container style={{ color: "white", marginTop: 50 }}>
                <Grid xs={12}>
                  Greens sem Gale:
                  {
                    <span style={{ marginLeft: 20, color: "green" }}>
                      {suggestionResults.reduce((accumulator, ar) => {
                        return accumulator + ar.greens;
                      }, 0)}
                    </span>
                  }
                </Grid>

                {showGale && (
                  <Grid xs={12} style={{ marginTop: 10 }}>
                    Greens no Gale:
                    {
                      <span style={{ marginLeft: 20, color: "green" }}>
                        {suggestionResults.reduce((accumulator, ar) => {
                          return accumulator + ar.gale1;
                        }, 0)}
                      </span>
                    }
                  </Grid>
                )}

                <Grid xs={12} style={{ marginTop: 10 }}>
                  Red:
                  {
                    <span style={{ marginLeft: 20, color: "red" }}>
                      {suggestionResults.reduce((accumulator, ar) => {
                        return accumulator + ar.reds;
                      }, 0)}
                    </span>
                  }
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Layout>
  );
}

export default App;

import React from "react";
import LinearProgressBar from "../../../../components/LinearProgressBar/LinearProgressBar";

type IndicatorProps = {
    value: number;
    title: string;
}

const Indicator = (props: IndicatorProps) =>{

    return (
        <>
            <div style={{textAlign: "center"}}>
                <h6 style={{color: "#FFF", margin: 0}}>
                    {props.title}
                </h6>
                <LinearProgressBar value={props.value} />
            </div>
        </>
    )
}

export default Indicator;
import useRouletteAnalysis from "../../../hooks/useRouletteAnalysis";
import useRouletteNumbersAnalysis from "../../../hooks/useRouletteNumbersAnalisys";
import { Colors } from "../../../models";

export type INumberHistoryProps = {
    numbers: number[];
    size?: number;
}

const NumberHistory = (props:INumberHistoryProps)=>{
    let numbers = props.numbers.slice().reverse();
    if(props.size && numbers.length > props.size) numbers = numbers.slice(0,props.size);
    
    const { transformNumber } = useRouletteNumbersAnalysis(numbers);

    let spans = numbers.map(n => {
        const tn = transformNumber(n);
        let color = "green";
        if(tn.color == Colors.PRETOS) color = "white";
        if(tn.color == Colors.VERMELHOS) color = "#b50606";
        return <span style={{marginRight:7, color }}>{n}</span>
    })

    return (
        <>
            <h3>
                {spans}
                {/* {numbers.join(" ")} */}
            </h3>
        </>
    )
}

export default NumberHistory;
import { api } from "../config/api";
import { ResponseAPI } from "../models/api";
import { ToggleStrategyRequest, User } from "../models/UserAccess";

export const GetAllUsers = async (): Promise<ResponseAPI<User[] | null>> => {
  try {
    const { data }: ResponseAPI<User[]> = await api.get(`/users`);

    return {
      data,
      success: true
    } as ResponseAPI<User[]>
  }
  catch (error: any) {
    return {
      success: false,
    } as ResponseAPI<null>
  }
};

export const CreateNewUser = async (user: User): Promise<ResponseAPI<User | null>> => {
  try {
    console.log(user);
    const { data }: ResponseAPI<User> = await api.post(`/users`, user);

    return {
      data,
      success: true
    } as ResponseAPI<User>
  }
  catch (error: any) {
    return {
      success: false,
    } as ResponseAPI<null>
  }
};

export const ToggleStrategy = async (toggleRequest: ToggleStrategyRequest): Promise<ResponseAPI<null>> => {
  try {
    console.log(toggleRequest);
    const { data }: ResponseAPI<User> = await api.post(`/users/ToggleStrategy`, toggleRequest);

    return {
      success: true,
      data:null
    } as ResponseAPI<null>
  }
  catch (error: any) {
    return {
      success: false,
    } as ResponseAPI<null>
  }
};
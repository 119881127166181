// import React, { useMemo } from 'react';
import { ExternalTypes, Colors, NumberTypes } from "../models";
import { Confirmation } from "../models/Confirmation";
import { Columns } from "../models/enums/Columns";
import { Dozens } from "../models/enums/Dozens";
import { Pattern } from "../models/enums/Pattern";

interface TransformedArrays {
  colors: Colors[];
  oddEven: ExternalTypes[];
  numberTypes: NumberTypes[];
  dozens: Dozens[];
  columns: Columns[];
  terminals: number[];
}

export interface TransformedNumber {
  color: Colors;
  oddEven: ExternalTypes;
  numberType: NumberTypes;
  dozen: Dozens;
  column: Columns;
  number:number;
}

const BlackNumbers = [
  2, 4, 6, 8, 10, 11, 13, 15, 17, 20, 22, 24, 26, 28, 29, 31, 33, 35,
];
const RedNumbers = [
  1, 3, 5, 7, 9, 12, 14, 16, 18, 19, 21, 23, 25, 27, 30, 32, 34, 36,
];
const columns = {
  C1: [1, 4, 7, 10, 13, 16, 19, 22, 25, 28, 31, 34],
  C2: [2, 5, 8, 11, 14, 17, 20, 23, 26, 29, 32, 35],
  C3: [3, 6, 9, 12, 15, 18, 21, 24, 27, 30, 33, 36],
};
const dozens = {
  D1: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  D2: [13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24],
  D3: [25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36],
};

const useRouletteAnalysis = (numbersArray: number[]) => {
  const transformNumber = (number: number): TransformedNumber => {
    return {
      number: number,
      color:
        BlackNumbers.indexOf(number) !== -1
          ? Colors.PRETOS
          : RedNumbers.indexOf(number) !== -1
          ? Colors.VERMELHOS
          : Colors.VERDE,
      oddEven: number % 2 === 0 ? ExternalTypes.PAR : ExternalTypes.IMPAR,
      numberType:
        number === 0
          ? NumberTypes.ZERO
          : number < 19
          ? NumberTypes.BAIXOS
          : NumberTypes.ALTOS,
      column:
        columns.C1.indexOf(number) !== -1
          ? Columns.C1
          : columns.C2.indexOf(number) !== -1
          ? Columns.C2
          : Columns.C3,
      dozen:
        dozens.D1.indexOf(number) !== -1
          ? Dozens.D1
          : dozens.D2.indexOf(number) !== -1
          ? Dozens.D2
          : Dozens.D3,
    };
  };

  const TransformArray = (): TransformedArrays => {
    const result: TransformedArrays = {
      colors: [],
      numberTypes: [],
      oddEven: [],
      dozens: [],
      columns: [],
      terminals: []
    };

    numbersArray.forEach((n) => {
      const transformedNumbers = transformNumber(n);
      result.colors.push(transformedNumbers.color);
      result.numberTypes.push(transformedNumbers.numberType);
      result.oddEven.push(transformedNumbers.oddEven);
      result.columns.push(transformedNumbers.column);
      result.dozens.push(transformedNumbers.dozen);
      result.terminals.push(Number(`${n}`.slice(-1)));
    });

    return result;
  };

  const numbers = TransformArray();

  const checkBrokenLogic = (items: any[]): boolean => {
    if (items.length < 5) return false;

    return (
      items[0] === items[1] &&
      items[1] === items[2] &&
      items[2] === items[3] &&
      items[3] !== items[4]
    );
  };

  const AlienDozenLogic = (): Confirmation[] => {
    const result: Confirmation[] = [];
    const D1D2Numbers = [3,5,7,10,12,16,21,25,26,28,29,30,31];
    const D1D3Numbers = [1,6,8,9,11,24,33,34,36];
    const D2D3Numbers = [2,4,13,14,15,17,18,19,20,22,23,27,32,35];
    const lastNumber = numbersArray[numbersArray.length - 1];

    if (D1D2Numbers.indexOf(lastNumber) !== -1)
      result.push({
        betText: `-> Apostar em D1 e D2`,
        pattern: Pattern.AlienDozen,
        trigger: lastNumber,
        match: [Dozens.D1,Dozens.D2]
      });

    if (D1D3Numbers.indexOf(lastNumber) !== -1)
      result.push({
        betText: `-> Apostar em D1 e D3`,
        pattern: Pattern.AlienDozen,
        trigger: lastNumber,
        match: [Dozens.D1,Dozens.D3]
      });

    if (D2D3Numbers.indexOf(lastNumber) !== -1)
      result.push({
        betText: `-> Apostar em D2 e D3`,
        pattern: Pattern.AlienDozen,
        trigger: lastNumber,
        match: [Dozens.D2,Dozens.D3]
      });

    return result;
  };

  const AlienDozenBrokenLogic = (): Confirmation[] => {
    const result: Confirmation[] = [];
    const D1D2Numbers = [1,5,8,12,18,20,22,23,24,25,26,32];
    const D1D3Numbers = [4,6,9,10,13,15,21,27,30,35];
    const D2D3Numbers = [2,3,7,11,14,16,17,19,28,29,31,33,34,36];
    const lastNumber = numbersArray[numbersArray.length - 1];

    if (D1D2Numbers.indexOf(lastNumber) !== -1)
      result.push({
        betText: `-> Apostar em D1 e D2`,
        pattern: Pattern.AlienDozen_Broken,
        trigger: lastNumber,
        match: [Dozens.D1,Dozens.D2]
      });

    if (D1D3Numbers.indexOf(lastNumber) !== -1)
      result.push({
        betText: `-> Apostar em D1 e D3`,
        pattern: Pattern.AlienDozen_Broken,
        trigger: lastNumber,
        match: [Dozens.D1,Dozens.D3]
      });

    if (D2D3Numbers.indexOf(lastNumber) !== -1)
      result.push({
        betText: `-> Apostar em D2 e D3`,
        pattern: Pattern.AlienDozen_Broken,
        trigger: lastNumber,
        match: [Dozens.D2,Dozens.D3]
      });

    return result;
  };

  const AlienColP1Logic = (): Confirmation[] => {
    const result: Confirmation[] = [];
    const C1C2Numbers = [1,4,7,8,9,10,11,14,17,19,20,21,22,25,27,28,29,30,32,36];
    const C1C3Numbers = [3,12,15,24,26,31];
    const C2C3Numbers = [2,5,6,13,16,18,23,33,34,35];
    const lastNumber = numbersArray[numbersArray.length - 1];

    if (C1C2Numbers.indexOf(lastNumber) !== -1)
      result.push({
        betText: `-> Apostar em C1 e C2`,
        pattern: Pattern.AlienP1,
        trigger: lastNumber,
        match: [Columns.C1,Columns.C2]
      });

    if (C1C3Numbers.indexOf(lastNumber) !== -1)
      result.push({
        betText: `-> Apostar em C1 e C3`,
        pattern: Pattern.AlienP1,
        trigger: lastNumber,
        match: [Columns.C1,Columns.C3]
      });

    if (C2C3Numbers.indexOf(lastNumber) !== -1)
      result.push({
        betText: `-> Apostar em C2 e C3`,
        pattern: Pattern.AlienP1,
        trigger: lastNumber,
        match: [Columns.C2,Columns.C3]
      });

    return result;
  };

  const AlienColP3Logic = (): Confirmation[] => {
    const result: Confirmation[] = [];
    const C1C2Numbers = [8,12,14,17,21,27,29,31,32];
    const C1C3Numbers = [1,2,3,4,5,6,10,11,13,15,19,23,28,30,33,34,36];
    const C2C3Numbers = [7,9,16,18,20,22,24,25,26,35];
    const lastNumber = numbersArray[numbersArray.length - 1];

    if (C1C2Numbers.indexOf(lastNumber) !== -1)
      result.push({
        betText: `-> Apostar em C1 e C2`,
        pattern: Pattern.AlienP3,
        trigger: lastNumber,
        match: [Columns.C1,Columns.C2]
      });

    if (C1C3Numbers.indexOf(lastNumber) !== -1)
      result.push({
        betText: `-> Apostar em C1 e C3`,
        pattern: Pattern.AlienP3,
        trigger: lastNumber,
        match: [Columns.C1,Columns.C3]
      });

    if (C2C3Numbers.indexOf(lastNumber) !== -1)
      result.push({
        betText: `-> Apostar em C2 e C3`,
        pattern: Pattern.AlienP3,
        trigger: lastNumber,
        match: [Columns.C2,Columns.C3]
      });

    return result;
  };

  const AlienColP2Logic = (): Confirmation[] => {
    const result: Confirmation[] = [];
    const C1C2Numbers = [6,14,16,22,24,26,31,32];
    const C1C3Numbers = [5,9,10,12,13,18,19,21,27,28,30,33,34];
    const C2C3Numbers = [1,2,3,4,7,8,11,15,17,20,23,25,29,35,36];
    const lastNumber = numbersArray[numbersArray.length - 1];

    if (C1C2Numbers.indexOf(lastNumber) !== -1)
      result.push({
        betText: `-> Apostar em C1 e C2`,
        pattern: Pattern.AlienP2,
        trigger: lastNumber,
        match: [Columns.C1,Columns.C2]
      });

    if (C1C3Numbers.indexOf(lastNumber) !== -1)
      result.push({
        betText: `-> Apostar em C1 e C3`,
        pattern: Pattern.AlienP2,
        trigger: lastNumber,
        match: [Columns.C1,Columns.C3]
      });

    if (C2C3Numbers.indexOf(lastNumber) !== -1)
      result.push({
        betText: `-> Apostar em C2 e C3`,
        pattern: Pattern.AlienP2,
        trigger: lastNumber,
        match: [Columns.C2,Columns.C3]
      });

    return result;
  };

  // const PullNumbersLogic = (): Confirmation[] => {
  //   const lastNumber = numbersArray[numbersArray.length - 1];

  //   if(lastNumber != 1) return [];

  //   const pullNumbers = [
  //     [10,21,34,36], // after 1
  //   ];

  //   const result: Confirmation[] = [];
  //   const matches = pullNumbers[lastNumber - 1];

  //   result.push({
  //     betText: `Entrada nos números ${matches.join(" ")}`,
  //     pattern: getNPattern(lastNumber),
  //     match: matches as number[]
  //   });
    
  //   return result;
  // }

  // const getNPattern = (n:number): Pattern => {
  //   switch(n){
  //     case 1 : return Pattern.N1;
  //     default: return Pattern.N1;
  //   }
  // }

  const RouletteAnalisys = (): Confirmation[] => {
    const result: Confirmation[] = [
      ...AlienColP2Logic(),
      ...AlienColP1Logic(),
      ...AlienColP3Logic(),
      ...AlienDozenLogic(),
      ...AlienDozenBrokenLogic(),
      // ...PullNumbersLogic()
    ];
    return result;
  };

  return {
    RouletteAnalisys,
    transformNumber,
  };
};

export default useRouletteAnalysis;
